import logo from './logo.svg';
import './App.css';

function App() {
	window.location.href = 'https://forms.office.com/r/CbUtUGh7Wc'  

return (
    <div className="App">
      <header className="App-header">
      </header>
    </div>
  );
}

export default App;
